import { createGlobalStyle } from 'styled-components';

import FrizQuadrataBoldWOFF2 from '../fonts/FrizQuadrataBold/FrizQuadrataBold.woff2';
import FrizQuadrataBoldWOFF from '../fonts/FrizQuadrataBold/FrizQuadrataBold.woff';
import FrizQuadrataBoldTTF from '../fonts/FrizQuadrataBold/FrizQuadrataBold.ttf';
import FrizQuadrataBoldEOT from '../fonts/FrizQuadrataBold/FrizQuadrataBold.eot';
import FrizQuadrataBoldSVG from '../fonts/FrizQuadrataBold/FrizQuadrataBold.svg';

import FrizQuadrataTTWOFF2 from '../fonts/FrizQuadrataTT/Friz Quadrata TT.woff2';
import FrizQuadrataTTWOFF from '../fonts/FrizQuadrataTT/Friz Quadrata TT.woff';
import FrizQuadrataTTTTF from '../fonts/FrizQuadrataTT/Friz Quadrata TT.ttf';
import FrizQuadrataTTEOT from '../fonts/FrizQuadrataTT/Friz Quadrata TT.eot';
import FrizQuadrataTTSVG from '../fonts/FrizQuadrataTT/Friz Quadrata TT.svg';

export default createGlobalStyle`
  @font-face {
    font-family: "Friz Quadrata Bold";
    src: url(${FrizQuadrataBoldEOT}); /* IE9*/
    src: url(${FrizQuadrataBoldEOT}#iefix) format("embedded-opentype"), /* IE6-IE8 */
    url(${FrizQuadrataBoldWOFF2}) format("woff2"), /* chrome, firefox */
    url(${FrizQuadrataBoldWOFF}) format("woff"), /* chrome, firefox */
    url(${FrizQuadrataBoldTTF}) format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("${FrizQuadrataBoldSVG}#Friz Quadrata Bold") format("svg"); /* iOS 4.1- */
  }
  @font-face {
    font-family: "Friz Quadrata TT";
    src: url(${FrizQuadrataTTEOT}); /* IE9*/
    src: url(${FrizQuadrataTTEOT}#iefix) format("embedded-opentype"), /* IE6-IE8 */
    url(${FrizQuadrataTTWOFF2}) format("woff2"), /* chrome, firefox */
    url(${FrizQuadrataTTWOFF}) format("woff"), /* chrome, firefox */
    url(${FrizQuadrataTTTTF}) format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("${FrizQuadrataTTSVG}#Friz Quadrata TT") format("svg"); /* iOS 4.1- */
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    overflow: auto;
    width: 100vw;
    height: 100%;
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: 18px;
    line-height: 1.44;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';

    @media ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 16px;
    }
    @media ${({ theme }) => theme.breakpoints.mobile} {
      font-size: 14px;
    }

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }

  body {
    width: 100vw;
    height: 100%;
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  a {
    color: inherit;
    background-color: transparent;
    text-decoration: none;
    -webkit-text-decoration-skip: objects;

    &:active,
    &:hover {
      outline-width: 0;
    }
  }

  article,
  aside,
  details,
  div,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  ul,
  ol {
    list-style-position: outside;
    list-style-image: none;
  }

  li {
    list-style: none;
  }

  p {
    text-align: justify;
    margin-bottom: 1rem;
  }

  table {
    line-height: 1.5;
    border-collapse: collapse;

    thead {
      font-weight: 700;
      text-align: center;
    }
  }

  h1 {
    font-size: 2.25rem;
    letter-spacing: 0.15rem;
    line-height: 1.1;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    margin: 0.67rem 0 2rem;
  }

  h2 {
    font-size: 1.66rem;
    line-height: 1.1;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    margin: 0 0 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.1;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    margin: 0 0 1.4rem;
  }

  h4 {
    font-size: 1.25rem;
    line-height: 1.1;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    margin: 0 0 1.45rem;
  }

  h5 {
    font-size: 1rem;
    line-height: 1.1;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    margin: 0 0 1.45rem;
  }

  h6 {
    font-size: 0.8rem;
    line-height: 1.1;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    margin: 0 0 1.45rem;
  }
`;
