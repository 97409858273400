const palette = {
  black: '#000',
  grey10: '#111',
  grey20: '#333',
  grey30: '#777',
  grey40: '#ccc',
  grey50: '#eee',
  white: '#fff',

  blue10: '#0a2e70',
  blue20: '#123e8f',
  blue30: '#2956a6', // urbis
  blue40: '#456cb3',
  blue50: '#6B8dca',

  green10: '#098755',
  green20: '#02a564',
  green30: '#9be98d',
  green40: '#d2f9a8',
  green50: '#e3ffcf',

  yellow10: '#ffc100',
  yellow20: '#fee056',
  yellow30: '#fcef73',
  yellow40: '#fffca8',
  yellow50: '#fffedc',

  red10: '#c43535',
  red20: '#f94c4c',
  red30: '#ff7e73',
  red40: '#ffb9b4',
  red50: '#ffdbd8',

  hsq: '#263a60',
  hsqShade: '#1d2c47',
  stihl: '#f46717',
  stihlShade: '#e75a0b',
};

const blackGlassSwatch = {
  navBgPrim: 'rgba(0, 0, 0, 0.55)',
  navBgSec: 'rgba(0, 0, 0, 0.33)',
  navText: palette.white,
  bgPrim: 'rgba(200, 200, 200, 0.66)',
  text: palette.black,
};

export default {
  fonts: {
    main: 'Muli, sans-serif',
    urbis: 'Friz Quadrata TT, sans-serif',
    urbisB: 'Friz Quadrata Bold, sans-serif',
  },
  swatches: blackGlassSwatch,
  palette,
  shadow: '0 0.5rem 0.5rem rgba(0, 0, 0, 0.25)',
  glow: '0 0 0.35rem 0 rgba(255, 255, 255, 0.5), inset 0 0 0.35rem 0 rgba(255, 255, 255, 0.5)',
  glowText: '0 0 0.35rem rgba(255, 255, 255, 0.25)',
  animations: {},
  breakpoints: {
    mobile: 'only screen and (max-width: 720px)',
    tablet: 'only screen and (max-width: 1080px)',
  },
};
